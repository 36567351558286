import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/react';
import { useState, useEffect, useCallback } from 'preact/hooks';
import Match from 'preact-router/match';

import classnames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import TermsModal from '../../components/termsModal';
import Header from '../../components/header';
import Button from '../../components/common/button';
import Intl from '../../components/common/intl';
import EventGuard from '../../components/eventGuard';
import StadiumSlide from './components/stadiumSlide';
import PrivacySlide from './components/privacySlide';

import { route } from '../../helpers/router';
import { sendLog } from '../../helpers/apis/logger';
import { isPreviewMode } from '../../helpers/preview';
import { setTempSrs } from '../findme/srs/actions';

import style from './style.scss';

let timeout;

const useDotButton = (emblaApi) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const onInit = useCallback((carouselApi) => {
    setScrollSnaps(carouselApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((carouselApi) => {
    setSelectedIndex(carouselApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

const Onboarding = ({
  srs,
  isTermsAccepted,
  geolocationEnabled,
  geofence,
  ticketId,
  setSeatType,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({});
  const [shouldAutoplay, setShouldAutoplay] = useState(true);
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const onClickHandler = () => {
    if (!isTermsAccepted) {
      route('/terms');
    } else if (geofence.enabled && !geolocationEnabled) {
      route('/location');
    } else {
      route('/findme');
    }
  };

  const logSlideChange = (value, type) => {
    const context = {
      type: 'slideChange',
      toSlide: value,
      transitionType: type,
    };
    sendLog('info', context);
  };

  const onChangeHandler = (value) => {
    logSlideChange(value, 'triggered');
    onDotButtonClick(value);
    if (shouldAutoplay) {
      clearTimeout(timeout);
      setShouldAutoplay(false);
    }
  };

  useEffect(() => {
    // this logic will skip onboarding and redirect to terms if ticket info exists
    if (!isTermsAccepted && ticketId && srs.section && srs.row && srs.seat && srs.type) {
      if (['box', 'suite'].includes(srs.type) && srs.row === '1' && srs.seat === '1') {
        const skipped = { row: true, seat: true };
        setSeatType({}, skipped);
      }

      route('/terms');
    }
  }, [ticketId]);

  useEffect(() => {
    if (selectedIndex === 1) {
      onChangeHandler(selectedIndex);
    }
  }, [selectedIndex]);

  const autoplayHandler = () => {
    if (shouldAutoplay) {
      timeout = setTimeout(() => {
        onDotButtonClick(1);
        logSlideChange(1, 'auto');
        setShouldAutoplay(false);
      }, 5000);
    }
  };

  return (
    <Fragment>
      <Header />
      <div className={style.page}>
        <div className={style.sliderContainer}>
          <section className={style.embla}>
            <div className={style.emblaViewport} ref={emblaRef}>
              <div className={style.emblaContainer}>
                <div className={style.emblaSlide} key={1} data-active={selectedIndex === 0}>
                  <StadiumSlide onImgLoad={autoplayHandler} />
                </div>
                <div className={style.emblaSlide} key={2} data-active={selectedIndex === 1}>
                  <PrivacySlide />
                </div>
              </div>
            </div>

            <div className={style.emblaControls}>
              <div className={style.emblaDots}>
                {scrollSnaps.map((_, index) => (
                  <button
                    type="button"
                    key={index} // eslint-disable-line react/no-array-index-key
                    onClick={() => onDotButtonClick(index)}
                    className={classnames(style.emblaDot, { [style.emblaDotSelected]: index === selectedIndex })}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
        <Button primary next onClick={onClickHandler}>
          <Intl textKey="I18N_NEXT_BUTTON">
            Next
          </Intl>
        </Button>
      </div>
      <Match path="/terms">
        {({ matches }) => matches && <TermsModal onClose={() => window.history.back()} />}
      </Match>
    </Fragment>
  );
};

const ConnectedComponent = connect(({
  srs,
  event: { geofence },
  termsAccepted,
  termsModalOpen,
  geolocationEnabled,
  ticketInfo: { ticketId } = {},
}) => ({
  srs,
  geofence,
  isTermsAccepted: termsAccepted,
  isTermsModalOpen: termsModalOpen,
  geolocationEnabled,
  ticketId,
}), () => ({ setSeatType: setTempSrs }))(Onboarding);

export default (props) => {
  if (isPreviewMode()) {
    return <ConnectedComponent {...props} />;
  }

  return (
    <EventGuard>
      <ConnectedComponent {...props} />
    </EventGuard>
  );
};
